import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import AdminAdd from './pages/Admin';
import Admin from './pages/AdminDashboard';
import UpdateAdmin from './pages/UpdateAdmin';

import ScrollToTop from './pages/ScrollToTop';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomFooter from './pages/Footer';

import { AuthProvider } from './pages/AuthContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Add the imported icons to the library
library.add(fas);
library.add(fab);


function App() {
    return (
        <div className="App">
           
     
                <BrowserRouter basename="/">
                <AuthProvider>
                <ToastContainer/>
            
                <ScrollToTop/>
                    <Routes>
                        <Route path="/" element={<Home />}/>
                        <Route path="/Admin/Add" element={<AdminAdd />}/>
                        <Route path="/Admin" element={<Admin />}/>
                        <Route path="/updateadmin/:id" element={<UpdateAdmin />}/>
   
                    </Routes>
                    <CustomFooter/>
                    </AuthProvider>
                </BrowserRouter>
     
 
        </div>
    );
}

export default App;

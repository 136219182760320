import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Navbar from "./Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import theme,{WEB} from "../theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const validationSchema = Yup.object().shape({
  headline: Yup.string().required("headline is required"),
  description: Yup.string().required("description is required"),

});

const Updateevent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const event = location.state && location.state.clientData;
  const eventId = event.eventid;
  const [isEditing, setIsEditing] = useState(true);



  const formik = useFormik({
    initialValues: {
         headline: event.headline,
         description: event.description,
         eventimage: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      // Upload eventimage first if provided
      if (values.eventimage) {
        await uploadeventimage(values.eventimage);
      }

      // Update event information
      axios
        .put(`${WEB.Backend}node/updateevent/${eventId}`, values)
        .then((response) => {
          console.log(response.data.message);
          toast.success(response.data.message);
         
          navigate("/admin");
        })
        .catch((error) => {
          console.error("Error updating event:", error);
          toast.error("Something failed. Please try again.");
        });
    },
  });

  const handleFileChange = (e) => {
    formik.setFieldValue("eventimage", e.target.files[0]);
  };

  const uploadeventimage = async (eventimage) => {
    try {
      const formData = new FormData();
      formData.append("eventimage", eventimage);

      const response = await axios.post(
        `${WEB.Backend}node/updateimage/${eventId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("eventimage uploaded successfully:", response.data.message);
    } catch (error) {
      console.error("Error uploading eventimage:", error);
    }
  };
  //County Province City
  
  const handleeventimageClick = () => {
    document.getElementById("eventimageInput").click();
  };

  return (
    <>
      <Navbar />
      <div className="container ">
        <div className="container bg-light mb-3 mt-2 text-dark">
          <div className="container col pt-2">
           
             <div className="d-flex row">
          
            <div className="" style={{justifyContent:'space-between',display:'flex'}}>
<div>
                {isEditing ? (
                  
                  
                  
                  
                  <div onClick={handleeventimageClick}>
                  {formik.values.eventimage ?<img
                  className="rounded"
                    style={{ height: "5rem", width: "5rem" }}
                    src={URL.createObjectURL(formik.values.eventimage)}
                  />:<img
                  style={{ height: "5rem", width: "5rem" }}
                  src={`${WEB.Backend}node/uploads/${event.eventimage}`}
                />}
                  <input hidden  id="eventimageInput"
                    className="form-control text-dark rounded-5 form-control" type="file" name="eventimage " onChange={handleFileChange} /></div>
                ) : (
                  <img
                    style={{ height: "5rem", width: "5rem" }}
                    src={`${WEB.Backend}node/uploads/${event.eventimage}`}
                  />
                )}
                </div>
              
              </div>
              
            </div>
            
          <h5 style={{ color: 'white' }}>Event Details</h5>
        
            <form onSubmit={formik.handleSubmit}>
              {/* Render input fields for each event property */}
            
            
               {/* Manual rendering of each input field */}
             
         
              <div className="col">
                <label htmlFor="headline">headline</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="headline"
                  name="headline"
                  value={formik.values.headline}
                  onChange={formik.handleChange}
                />
                {formik.touched.headline && formik.errors.headline && (
                  <div style={{ color: "red" }}>{formik.errors.headline}</div>
                )}
              </div>
              <div className="col">
                <label htmlFor="description">description</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.touched.description && formik.errors.description && (
                  <div style={{ color: "red" }}>{formik.errors.description}</div>
                )}
              </div>
         
            
             
                
            </form>
       
          
            <div className="row">
              <button
                className="col rounded-5 btn btn-dark text-light m-3"
                onClick={() => {
                  if (isEditing) {
                    formik.handleSubmit();
                  } else {
                    setIsEditing(true);
                  }
                }}
              >
                {isEditing ? "Save Changes" : "Edit Profile"}
              </button>
            </div>
          </div></div>
     
        </div>
      
    </>
  );
};

export default Updateevent;
                
  
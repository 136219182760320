import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import THEME from "../theme";
import logo from "../assets/hmcc.jpg";

const CustomNavbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-transparent text-center">
        <div className="container text-center" >
          <a className="navbar-brand"  href="/">
           <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img className="navlogo me-2"
              src={logo}
              alt="Logo"
         
              
            />
            <h1
              className="navtitle"
            
            >
              HMCC Consulting
            </h1>
      </div>
           </a>
          <button
            className="ms-5 navbar-toggler text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon="bars" />
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>
          <div
            className="collapse navbar-collapse text-center ms-5"
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav me-auto"></ul>
            <form className="text-center">
              <div className="navbar-nav text-center ">
              

                <div className="row">
                  <div className="me-5 mt-2 col" >
                    <a
                      href="/"
                      className="  text-light nav-link"
                    >
                      Home 
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="me-5 mt-2 col" >
                    <a
                      href="#about"
                      className="  text-light nav-link"
                    >
                      About
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="service me-5 mt-2 col" >
                    <a
                      href="#services"
                      className="  text-light nav-link"
                    >
                      Services
                       {/* <FontAwesomeIcon icon="angle-down" /> */}
                    </a>
                    {/* <div className="hdlinks">
                    
          <a className='servicelinkitem' href="#">English</a><br/>
          <a className='servicelinkitem' href="#">Chinese</a><br/>
          <a className='servicelinkitem' href="#">Urdu</a><br/>
          <a className='servicelinkitem' href="#">Arabic</a><br/>
          <a className='servicelinkitem' href="#">Sindhi</a><br/>
      
                    </div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="me-5 mt-2 col" >
                    <a
                      href="#events"
                      className="  text-light nav-link"
                    >
                      Events
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="me-5 mt-2 col">
                    <a
                      href="#ourteam"
                      className="  text-light nav-link"
                    >
                      Our Team
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default CustomNavbar;

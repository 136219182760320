import React, { useState,useEffect } from "react";
import Navbar from "./Navbar";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import theme, { WEB } from "../theme";
import logo from "../assets/hmcc.jpg";
import logo2 from "../assets/sdg.jpg";
import logo3 from "../assets/research.jpeg";
import logo4 from "../assets/research2.jpeg";
import logo5 from "../assets/human.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Dashboard() {
  const [event, setevent] = useState();
  const navigate = useNavigate();

  const [clients, setClientData] = useState(null);
  const [eventstatus, seteventStatus] = useState(null);
  const [sortBy, setSortBy] = useState("headline"); // Default sorting by name
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);
  const [clientToView, setClientToView] = useState(null);

  const [activeLink, setActiveLink] = useState("home");
  const handleClick = (link) => {
    setActiveLink(link);
  };



  
  const Getevent = () => {
    const events = localStorage.getItem("event");
    const data = JSON.parse(events);
   
      setevent(data);
      const eventid = data && data.eventid;
      fetch(`${WEB.Backend}node/getevents`)
        .then((response) => response.json())
        .then((data2) => {
          console.log(data2);
          setClientData(data2.data);
          console.log(data2.data);
          setCount(data2.data.length);
        })

        .catch((error) => console.log(error));
 
  };

  useEffect(() => {
    Getevent();
  }, [clients]);

  useEffect(() => {
    Getevent();
  }, []);

  useEffect(() => {
    Getevent();
  }, []);



  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort);
  };
  const handleOrderChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const sortedClients = clients ? [...clients] : [];
  sortedClients.sort((a, b) => {
    const compareValue = (valueA, valueB) => {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    };

    const orderMultiplier = sortOrder === "asc" ? 1 : -1;

    switch (sortBy) {
      case "headline":
        return compareValue(a.headline, b.headline) * orderMultiplier;
      case "description":
        return compareValue(a.description, b.description) * orderMultiplier;
      case "eventimage":
        return compareValue(a.eventimage, b.eventimage) * orderMultiplier;
      case "eventid":
        return compareValue(a.eventid, b.eventid) * orderMultiplier;
      default:
        return 0;
    }
  });

  const handleShowModal = (client) => {
    setShowModal(true);
    setClientToView(client);
  };
  const handleCloseModal = () => setShowModal(false);


  return (
    <>
      <div className="App">
        <div className="AppBG"></div> {/* Background image layer */}
        <div className="bg-overlay"></div> {/* Dark overlay layer */}
        <div className="content">
          <div className="toplinks">
            <a className="toplinkitem" href="#home">
              Home
            </a>
            <a className="toplinkitem" href="#about">
              About us
            </a>
            <a className="toplinkitem" href="#services">
              Services
            </a>
            <a className="toplinkitem" href="#events">
              Events
            </a>
            <a className="toplinkitem" href="#ourteam">
              Our Team
            </a>
          </div>
          <br />
          <br />
          <div className="nav">
            <Navbar />
          </div>
          <div className="headtitle" style={{}}>
            <p>Introduction to HMCC</p>
            <h1 className="heading">
              Welcome to HMCC, where excellence meets innovation
            </h1>
          </div>
        </div>
        <div className="sticky">
          <div className="linktab">
            <a
              href="#home"
              className={`linktabitem ${activeLink === "home" ? "active" : ""}`}
              onClick={() => handleClick("home")}
            >
              Home
            </a>
            <a
              onClick={() => handleClick("services")}
              className={`linktabitem ${
                activeLink === "services" ? "active" : ""
              }`}
              href="#about"
            >
              About Us
            </a>
            <a
              onClick={() => handleClick("team")}
              className={`linktabitem ${activeLink === "team" ? "active" : ""}`}
              href="#services"
            >
              Services
            </a>
            <a
              onClick={() => handleClick("events")}
              className={`linktabitem ${
                activeLink === "events" ? "active" : ""
              }`}
              href="#events"
            >
              Events
            </a>
            <a
              onClick={() => handleClick("contact")}
              className={`linktabitem ${
                activeLink === "contact" ? "active" : ""
              }`}
              href="#ourteam"
            >
              Our Team
            </a>
          </div>
        </div>
        <div id="home" className="homesection" name="home" style={{}}>
          <div className="homecontainer">
            <div>
              <h4>Welcome to HMCC, where excellence meets innovation.</h4>
            </div>
            <div>
              <p>
                Since our inception, we've been committed to redefining
                management consulting by offering expertise, creativity, and
                dedication in every engagement. In today's rapidly changing
                business landscape, strategic guidance and innovative solutions
                are crucial. At HMCC, we understand your challenges and
                opportunities, providing confidence and clarity as your trusted
                partner. Whether you're a startup scaling up, an established
                enterprise optimizing operations, or a multinational exploring
                new markets, HMCC is here for you. Our seasoned professionals
                bring diverse experience, insights, and fresh perspectives to
                drive tangible results tailored to your needs. Join us in
                unlocking your business's full potential and achieving
                extraordinary outcomes.
              </p>
            </div>
            <div>
              <p>Welcome to HMCC – where your success is our mission. </p>
            </div>
          </div>
          <div className="hiddenmob" style={{}}>
            <div>
              <img style={{ height: 160, width: 160 }} src={logo}></img>
              <p>HMCC Consulting</p>
            </div>
          </div>
        </div>
        <div id="about" name="about" style={{ display: "flex" }}>
          <div className="gradbg">
            <div className="homecontainer">
              <div>
                <h4>What We Do</h4>
              </div>
              <div>
                <p>
                  At HMCC, our mission is clear: to provide unparalleled
                  professional services empowering businesses to thrive. We
                  specialize in:
                </p>
              </div>
              <div>
                <ul>
                  <li>ESG Compliance and Policy support on SDG</li>  <br />
                 <li> Human Rights Due Diligence (HRDD)</li><br />
                 <li> Project Management and Monitoring & Evaluation (M&E)</li><br />
                 <li> Research </li>
                </ul>
              </div>
            </div>
            <div className="hiddenmob">
              <div>
                <img
                  style={{ height: 200, width: 200 }}
                  src={require("./../assets/hmcc.jpg")}
                ></img>
              </div>
            </div>
          </div>
        </div>
     
   

       






        <div style={{ padding:20,backgroundColor: "#00084d" }}>
        <h2
          id="services"
          name="services"
          className="flaround"
          style={{ color:'white',marginTop: 60,marginBottom:20, }}
        >
          Services
        </h2>
          <div className="servicefl">
            <div
              className="servicecard"
              style={{
                
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "flex-start",
                marginBottom:'15%'
              }}
            >
              
              <div style={{padding:40}} className="servicesection">
                <div className="">
                  <div>
                    <img style={{ height: 240, width: 240 }} src={logo2}></img>
                    
                  </div>
                  <div>
                    <h5>ESG Compliance and Policy support on SDG</h5>
                    <p>
                      We recognize the importance of ESG in fostering
                      sustainable business practices and aligning with the UN
                      Sustainable Development Goals (SDGs). Our comprehensive
                      services cover every aspect of ESG and SDGs, ensuring your
                      organization thrives sustainably. Our offerings include:
                      <ul>
                        <li>Sustainability strategy development</li>
                        <li>ESG reporting and compliance</li>
                        <li>Stakeholder engagement</li>
                        <li>Risk assessment and management</li>
                        <li>Impact investing advisory</li>
                        <li>SDG technical assistance</li>
                        <li>Corporate transition for SDG alignment</li>
                        <li>
                          Training programs integrating SDG and ESG principles
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="servicecard"
              style={{
                
                
                      display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                alignContent: "flex-end",
                alignSelf:'flex-end',
                marginTop:'15%'
              }}
            >
              <div style={{padding:40}} className="servicesection">
                <div className="">
                  <div>
                    <img style={{ height: 180, width: 180 }} src={logo5}></img>
                    
                  </div>

                  <div>
                    <h5>Human Rights Due Diligence (HRDD)</h5>
                    <p>
                      Upholding human rights is fundamental to ethical business
                      conduct. Through our Human Rights Due Diligence (HRDD)
                      services, we assist organizations in identifying,
                      addressing, and mitigating human rights risks across their
                      operations and supply chains. Our HRDD services include:
                      <ul>
                        <li>
                          Human rights risk assessment (e.g., child labor,
                          forced labor, discrimination, health and safety
                          hazards)
                        </li>
                        <li>Policy development and implementation</li>
                        <li>Training and capacity building</li>
                        <li>Supply chain analysis and monitoring</li>
                        <li>
                          Prevention, Remediation strategy, and grievance
                          mechanisms
                        </li>
                        <li>
                          Design and operationalise Human Rights Guidebooks and
                          Manuals
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
          <div className="servicefl">
            <div
              className="servicecard"
              style={{
                
               
                      display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "flex-start",
                alignSelf:'flex-start',
                marginBottom:'15%'
              }}
            >
              <div style={{padding:40}} className="servicesection">
                <div className="">
                  <div>
                    <img style={{ height: 180, width: 180 }} src={logo3}></img>
                    
                  </div>

                  <div>
                    <h5>
                      Project Management and Monitoring & Evaluation (M&E)
                    </h5>
                    <p>
                      We specialize in precise project execution and evaluation
                      to drive organizational growth. Our combined project
                      management and M&E services ensure that projects are
                      delivered effectively and impactfully from inception to
                      completion. Our services include:
                      <ul>
                        <li> Project planning and risk assessment </li>
                        <li> Capacity assessment </li>
                        <li> Work package development </li>
                        <li> Progress mapping </li>
                        <li> Resource allocation and financial management </li>
                        <li> Design and implementation of M&E frameworks </li>
                        <li> Data collection and analytics </li>
                        <li> Performance evaluation and impact assessment </li>
                        <li> Third Party Evaluation </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="servicecard"
              style={{
                
                
                      display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                alignContent: "flex-end",
                alignSelf:'flex-end',
                marginTop:'15%',
              }}
            >
              <div style={{padding:40}} className="servicesection">
                <div className="">
                  <div>
                    <img style={{ height: 180, width: 180 }} src={logo4}></img>
                    
                  </div>

                  <div>
                    <h5>Research</h5>
                    <p>
                      Our research services provide comprehensive insights and
                      analyses throughout the project lifecycle:
                      <ul>
                        <li>Baseline studies </li>
                        <li>Midterm evaluations </li>
                        <li>End-term evaluations </li>
                        <li>Comprehensive research and analysis</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
        <h1
          id="events"
          name="events"
          className="flaround"
          style={{ color:'black',marginTop: 50 }}
        >
          Events
        </h1>
        <div style={{
                
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                flexWrap:'wrap',
        
              }}>
                 {clients &&
            sortedClients.map((client) => (
              <div   key={client.clientid}>



                    <div className="m-1" >
                      <div className="m-1">
                        <div style={{minHeight:350}}>
                          {client.eventimage !== null && (
                            <img
                              style={{ width: "350px", minHeight: 350 }}
                              className="img-fluid"
                              src={`${WEB.Backend}node/uploads/${client.eventimage}`}
                            />
                          )}
                        </div>
                        <div className="col">
                          <div
                            className="col bg-light text-dark p-2 rounded-2"
                            style={{ height: 100 }}
                          >
                            <div
                              className="m-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            ></div>
                            <div
                              className="m-1"
                              style={{
                                
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="col">
                                <h5 style={{  fontWeight: "bold" }}>
                                  {client.headline}
                                </h5>
                              </div>
                              <div className="flaround">
                                <p style={{width: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis' }}>
                                  {client.description}</p><button
                              className="btn mb-2"
                              style={{ color: "#00315b", fontSize: 14 }}
                              onClick={() => handleShowModal(client)}
                            >
                              <FontAwesomeIcon icon={"search"} />View Details
                            </button>
                              </div>
                               
                            
                             
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="col text-dark  rounded-2"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className="col">
                        

                           
                            <Modal scrollable show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>

                                <Modal.Title>{clientToView && clientToView.headline}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                <div>
                          {client.eventimage !== null && (
                            <img
                              style={{ width: "250px", height: "100%" }}
                              className="img-fluid"
                              src={`${WEB.Backend}node/uploads/${clientToView && clientToView.eventimage}`}
                            />
                          )}
                        </div> 
                                
                                  <p>
                               
                                    {clientToView &&
                                      clientToView.description}{" "}
                                  </p>
                                </div>
                         
                                <div className="row p-3">
                                  <button
                                    className="btn rounded-5 col"
                                    style={{
                                      backgroundColor: theme.COLORS.PRIMARY,
                                      color: "white",
                                    }}
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
              
            ))}
        </div>
        <div style={{ padding:20,marginTop:20,backgroundColor: "#00084d" }}></div>
        <h2 style={{marginTop:50,marginBottom:50}} id="ourteam" className="flaround">
          Our Team
        </h2>
        <div className="fl">
         
        <div className="socialcard">
            <div className="socialcarditem">
              <div className="avt">
                <img
                  src={require("./../assets/hmcc.jpg")}
                  className="avt"
                ></img>
              </div>
              <div className="flaround">
                <a href="https://www.linkedin.com/in/shehzer-amir-shaikh-5a17a748/">
                  <img
                    className="socialitem"
                    src={require("./../assets/linkedin.png")}
                  ></img>
                </a>
                <a href="https://wa.me/+923008293161">
                  <img
                    className="socialitem"
                    src={require("./../assets/whatsapp.png")}
                  ></img>
                </a>
                <a href="https://www.instagram.com/hmccglobal">
                  <img
                    className="socialitem"
                    src={require("./../assets/instagram.png")}
                  ></img>
                </a>
              </div>
            </div>
            <h4>Shehzer Amir Shaikh</h4>

            <p>
            Shehzer Amir Shaikh is a consultant with expertise in Corporate Governance, Social Entrepreneurship, and the Economics of Developing Countries, currently positioned at the United Nations Development Programme (UNDP). With a robust background in international management and economics, Shehzer has driven impactful initiatives across various sectors. His career highlights include roles at Deloitte, project management for USAID, and contributions to World Bank-funded initiatives, underscoring his commitment to advancing sustainable development and empowering communities. Based in Sindh, Shehzer excels in researching municipal service delivery, developing performance assessment frameworks, guiding Annual Development Planning processes, and conducting SDG-related trainings. His expertise extends to facilitating workshops on Human Rights Due Diligence for private sector implementation and preparing pitchbooks for donor-funded projects, particularly in sectors crucial to Pakistan's economic growth. Shehzer's work exemplifies a dedication to driving positive change and fostering sustainable development across Pakistan.
            </p>

            <br />
            {/* <br />
            <div className="socialicons">
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
            </div> */}
          </div>
          <div className="socialcard">
          <div className="socialcarditem">
            <div className="avt">
              <img src={require("./../assets/hmcc.jpg")} className="avt"></img>
            </div>
            <div className="flaround">
                <a href="http://linkedin.com/in/syed-mohammad-ali-7559167">
                  <img
                    className="socialitem"
                    src={require("./../assets/linkedin.png")}
                  ></img>
                </a>
              </div>
              </div>
            <h4>Dr. Syed Mohammad Ali </h4>
            <p>
              Dr. Syed Mohammad Ali is a development anthropologist with 25+ years
              of experience addressing socio-cultural, economic, and governance
              challenges, particularly in Pakistan. He has provided project
              design and evaluation expertise for prominent organizations like
              CARE International and various bilateral and multilateral
              initiatives. Dr. Ali is a prolific author, contributing to
              peer-reviewed articles, book chapters, and the book "Development,
              Poverty and Power in Pakistan" (Routledge, 2015). He teaches
              graduate-level courses at leading institutions such as Johns
              Hopkins University and American University, alongside serving as a
              Non-Resident Scholar at the Middle East Institute, Washington
              D.C., and a Non-Resident Fellow at Beaconhouse National
              University, Lahore.
            </p>

            <br />
            {/* <div className="socialicons">
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
            </div> */}
          </div>
          <div className="socialcard">
          <div className="socialcarditem">
            <div className="avt">
              <img src={require("./../assets/hmcc.jpg")} className="avt"></img>
            </div>
            <div className="flaround">
                <a href="https://www.linkedin.com/in/sheikhhammadamjad">
                  <img
                    className="socialitem"
                    src={require("./../assets/linkedin.png")}
                  ></img>
                </a>
              </div>
              </div>
            <h4>Sheikh Hammad Amjad </h4>
            <p>
              Sheikh Hammad Amjad is an entrepreneurial leader with over 15
              years of experience spanning diverse sectors in Pakistan and
              internationally. Specializing in stakeholder engagement, coaching,
              program management, business planning & strategy, and marketing &
              communication, he has made significant contributions as a
              Consultant & Trainer at the United Nations Development Programme
              (UNDP) and the International Trade Centre (ITC). Throughout his
              career, Sheikh has also worked with prestigious organizations like
              GIZ – German International Development Agency, the Federation of
              Pakistan Chambers of Commerce & Industry (FPCCI), and Nippon
              Express South Asia & Oceania, Japan. His roles have involved
              capacity development, training programs, and strategic consultancy
              aimed at fostering economic growth and sustainability.
            </p>

            <br />
            {/* <div className="socialicons">
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
            </div> */}
          </div>
          <div className="socialcard">
          <div className="socialcarditem">
            <div className="avt">
              <img src={require("./../assets/hmcc.jpg")} className="avt"></img>
            </div>
            <div className="flaround">
                <a href="https://www.linkedin.com/in/aijaz-ali-khuwaja-718a7865/">
                  <img
                    className="socialitem"
                    src={require("./../assets/linkedin.png")}
                  ></img>
                </a>
              </div>
              </div>
            <h4>Aijaz Ali Khuwaja </h4>
            <p>
            Aijaz Ali Khuwaja is a senior social development expert with over 15 years of experience in policy development, project management, and capacity building across various sectors in Pakistan and internationally. His specialties include Sustainable Development Goals (SDGs), climate change adaptation, human rights, decentralization, disaster risk reduction (DRR), and corporate social responsibility (CSR). Aijaz has collaborated with organizations including the UNDP, ITC-GRASP-UN, Plan International, and Aga Khan Development Network (AKDN). He is known for facilitating extensive training sessions on SDGs, human rights, climate change adaptation, microfinance, and child labor issues. Aijaz has authored three books on social development and contributed research articles online. His consultancy work involves preparing policy papers, developing performance frameworks, conducting social audits, and mobilizing resources for sustainable development initiatives.
            </p>

            <br />
            {/* <div className="socialicons">
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
              <FontAwesomeIcon icon="eye" />
            </div> */}
          </div>
         
        </div>
        {/* <div
          style={{
            display: "flex",
            padding: 120,
            justifyContent: "space-between",
          }}
        >
          <div>
            <FontAwesomeIcon color="tomato" size="5x" icon="bars" />
            Bar
          </div>
          <div>
            <FontAwesomeIcon color="tomato" size="5x" icon="key" />
            Key
          </div>
          <div>
            <FontAwesomeIcon color="tomato" size="5x" icon="home" />
            Door
          </div>
          <div>
            <FontAwesomeIcon color="tomato" size="5x" icon="lock" />
            Lock
          </div>
          <div>
            <FontAwesomeIcon color="tomato" size="5x" icon="eye" />
            eye
          </div>
        </div>
        <div className="gradbtn">Click Here</div> */}
        <hr />
        <h1 style={{ textAlign: "center" }}>Who We Work With</h1>
        <div className="">
          <div className="clientbox">
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client1.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client2.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client3.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client4.png")}
              />
            </div>
          </div>
          <div className="clientbox">
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client5.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client6.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client7.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client8.png")}
              />
            </div>
          </div>
          <div className="clientbox">
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client9.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client10.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/client11.png")}
              />
            </div>
          </div>
          </div>
        <hr />
        <h1 style={{ marginTop:50,textAlign: "center" }}>Affiliation</h1>
        <div className="">
          <div className="clientbox">
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/abc1.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/abc2.png")}
              />
            </div>
            <div>
              <img
                className="client"
                style={{}}
                src={require("../assets/abc3.png")}
              />
            </div>
            
          </div>
        </div>
        {/* <div className="countcard"></div>
        <div className="cg-overlay"></div> Dark overlay layer */}
        {/* <div className="content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              alignContent: "center",
              height: 400,
            }}
          >
            <h1 className="text-white">HMCC Consulting</h1>
           
          </div>
        </div> */}
      </div>
    </>
  );
}

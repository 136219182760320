import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import eventimage from "../logo.png";
import THEME, { WEB } from "../theme";

import { ProgressBar } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomNavbar from "./Navbar";

const RegisterForm = () => {
  const navigate = useNavigate();

  const [uploadProgress, setUploadProgress] = useState(0);

  const formik = useFormik({
    initialValues: {
      headline: "",
      description: "",
      eventimage: null,
    },
    validationSchema: Yup.object({
      headline: Yup.string().required("Headline is required"),
      description: Yup.string().required("Description is required"),
      eventimage: Yup.mixed().nullable(),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();

      formData.append("headline", values.headline);
      formData.append("description", values.description);
      formData.append("eventimage", values.eventimage);
      try {
        await axios.post(`${WEB.Backend}node/register`, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
            console.log(progress);
          },
        });

        toast.success("User registered successfully!");
        navigate("/admin");
      } catch (error) {
        console.error("Registration error: ", error.response.data.error);

        toast.error(error.response.data.error);
      }
    },
  });

  const handleeventimageClick = () => {
    document.getElementById("eventimageInput").click();
  };

  return (
    <div className="mainevent">
      <div style={{ backgroundColor: "#02074c" }}>
        <CustomNavbar />
      </div>
      <ToastContainer />
      <div className="container rounded-2 bg-light col-md-6 pb-5">
        <div className="col" style={THEME.Center}>
          <div className="p-3" style={THEME.ROW}>
            {/* <img className='rounded me-2' style={{width:70,height:70}}src={logo}></img> */}
          </div>

          <h6
            style={{ color: THEME.COLORS.PRIMARY }}
            className="text-center mb-4"
          >
            Add Event
          </h6>

          <div className="col">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col">
                  <label
                    className="form-label"
                    style={{ color: THEME.COLORS.PRIMARY }}
                  >
                    Event Image
                  </label>
                  <input
                    className="rounded-5"
                    id="eventimageInput"
                    type="file"
                    onChange={(e) =>
                      formik.setFieldValue("eventimage", e.target.files[0])
                    }
                    style={{ display: "none" }}
                  />
                  <div className="col" onClick={handleeventimageClick}>
                    {formik.values.eventimage ? (
                      // Show selected picture
                      <img
                        src={URL.createObjectURL(formik.values.eventimage)}
                        alt="Selected eventimage"
                        style={{ width: "20vw", height: "20vw" }}
                      />
                    ) : (
                      // Show eventimage image if nothing is selected
                      <img
                        src={eventimage}
                        alt="Default eventimage"
                        style={{ width: "20vw", height: "20vw" }}
                      />
                    )}
                  </div>
                </div>
                {formik.touched.eventimage && formik.errors.eventimage && (
                  <div className="text-dark">{formik.errors.eventimage}</div>
                )}
              </div>

              <div style={THEME.ROW}>
                <div className="mb-3">
                  <label
                    style={{ color: THEME.COLORS.PRIMARY }}
                    className="form-label"
                  >
                    headline:
                  </label>
                  <input
                    placeholder="headline"
                    type="text"
                    className="form-control rounded-5"
                    {...formik.getFieldProps("headline")}
                  />
                  {formik.touched.headline && formik.errors.headline && (
                    <div className="text-dark">{formik.errors.headline}</div>
                  )}
                </div>
              </div>

              <div style={THEME.ROW}>
                <div className="col">
                  <label
                    style={{ color: THEME.COLORS.PRIMARY }}
                    className="form-label"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    className="rounded-5 form-control"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="text-dark">{formik.errors.description}</div>
                  )}
                </div>
              </div>

              <ToastContainer />
              <div style={THEME.ROW}></div>

              <div className="row p-3" style={THEME.ROW}>
                <button
                  className="rounded-5 col btn"
                  style={{
                    backgroundColor: THEME.COLORS.PRIMARY,
                    color: "white",
                  }}
                  type="submit"
                >
                  Add Event
                </button>
                {uploadProgress > 0 && (
                  <ProgressBar
                    style={{ marginTop: 10, marginBottom: 10 }}
                    now={uploadProgress}
                    label={`${uploadProgress}%`}
                  />
                )}
              </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;

import React from 'react'
import logo from '../assets/hmcc.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Footer() {
  return (
    <div >
      <div className='footer'>
        <div className='homecontainer'><div><img src={logo} height={100}width={100} style={{margin:10}}></img>HMCC Consulting</div>

<p>Welcome to HMCC, where excellence meets innovation. Since our inception, we've been committed to redefining management consulting by offering expertise, creativity, and dedication in every engagement.
In today's rapidly changing business landscape, strategic guidance and innovative solutions are crucial. At HMCC, we understand your challenges and opportunities, providing confidence and clarity as your trusted partner.
Whether you're a startup scaling up, an established enterprise optimizing operations, or a multinational exploring new markets, HMCC is here for you. Our seasoned professionals bring diverse experience, insights, and fresh perspectives to drive tangible results tailored to your needs.
Join us in unlocking your business's full potential and achieving extraordinary outcomes.
</p>
<br/>

<p> Welcome to HMCC – where your success is our mission.</p>
</div>

        <div className='footercontact'>
        <h4>Contact us</h4>
          <div style={{lineHeight:0.8}}>
            <br/>
          {/* <div className='gradbtn'>Subscribe</div> */}
          <p>Email</p>
          <p>sashaikh@hmccconsulting.org</p>
          <br/>
          <p>Phone</p>
          <p>+923008293161</p>
          </div>
          <br/>
          <div><p>Social Media</p>
        <div className='socialicons' >
          <a href='https://www.facebook.com/hmccdigital/'><img className='socialitem' src={require('./../assets/facebook.png')}></img></a>
          <a href='https://www.linkedin.com/company/hmcc-global/'><img className='socialitem' src={require('./../assets/linkedin.png')}></img></a>

          <a href='https://www.instagram.com/hmccglobal'><img className='socialitem' src={require('./../assets/instagram.png')}></img></a>
          
          <a href='https://wa.me/+923008293161'><img className='socialitem' src={require('./../assets/whatsapp.png')}></img></a>
  
    </div>
    </div>
        </div>
        </div>
    </div>
  )
}

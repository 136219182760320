import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import theme, { WEB } from "../theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Dashboard() {
  const [event, setevent] = useState();
  const navigate = useNavigate();

  const [clients, setClientData] = useState(null);
  const [eventstatus, seteventStatus] = useState(null);
  const [sortBy, setSortBy] = useState("headline"); // Default sorting by name
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order
  const [showModal, setShowModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [count, setCount] = useState(0);

  const handleUpdateClick = (client) => {
    navigate(`/updateadmin/${client.eventid}`, {
      state: { clientData: client },
    });
  };
  const handleDeleteClick = async (client) => {
    try {
      const response = await axios.post(
        `${WEB.Backend}node/delete/${clientToDelete.eventid}`
      );

      if (response.data.message) {
        setClientData((prevClients) =>
          prevClients.filter((c) => c.eventid !== clientToDelete.eventid)
        );
        toast.success(response.data.message);
        setCount(count - 1);
        setShowModal(false);
      }
    } catch (error) {
      toast.error("Something failed Please Try Again");
    }
  };

  const Getevent = () => {
    const events = localStorage.getItem("event");
    const data = JSON.parse(events);
   
      setevent(data);
      const eventid = data && data.eventid;
      fetch(`${WEB.Backend}node/getevents`)
        .then((response) => response.json())
        .then((data2) => {
          console.log(data2);
          setClientData(data2.data);
          console.log(data2.data);
          setCount(data2.data.length);
        })

        .catch((error) => console.log(error));
 
  };

  useEffect(() => {
    Getevent();
  }, [clients]);

  useEffect(() => {
    Getevent();
  }, []);

  useEffect(() => {
    Getevent();
  }, []);



  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort);
  };
  const handleOrderChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const sortedClients = clients ? [...clients] : [];
  sortedClients.sort((a, b) => {
    const compareValue = (valueA, valueB) => {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    };

    const orderMultiplier = sortOrder === "asc" ? 1 : -1;

    switch (sortBy) {
      case "headline":
        return compareValue(a.headline, b.headline) * orderMultiplier;
      case "description":
        return compareValue(a.description, b.description) * orderMultiplier;
      case "eventimage":
        return compareValue(a.eventimage, b.eventimage) * orderMultiplier;
      case "eventid":
        return compareValue(a.eventid, b.eventid) * orderMultiplier;
      default:
        return 0;
    }
  });
  const handleShowModal = (client) => {
    setShowModal(true);
    setClientToDelete(client);
  };
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <div style={{ backgroundColor: "black" }}>
        <Navbar />
      </div>
      <div className="container">
        <div
          style={{
            height: 40,
            width: 200,
            backgroundColor: "limegreen",
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
            marginTop: 20,
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/admin/add"
          >
            Add New Event
          </Link>
        </div>
        <div className="container bg-light">
          <div style={theme.ROW}>
            <div
              className="col mb-3 bg-light text-dark p-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 40,
              }}
            >
              <div className="col-2">
                <label>Photo</label>
              </div>
              <div className="col-2">
                <label>Headline</label>
              </div>
              <div className="col-2">
                <label>Description</label>
              </div>
            
            </div>
          </div>
          <div style={theme.ROW}>
            <p className="ms-3 text-dark">
              Total events :{" "}
              {clients && clients.length > 0 ? clients.length - 1 : "No events"}
            </p>
          </div>
          <div style={theme.ROW}>
            <label className="ms-3 text-dark">Sort by:</label>
            <select
              className="form-select"
              value={sortBy}
              onChange={handleSortChange}
            >
              <option value="eventid">eventid</option>
              <option value="headline">headline</option>
              <option value="description">description</option>
            </select>
            <button className="btn btn-dark" onClick={handleOrderChange}>
              {sortOrder === "asc" ? "Asc" : "Desc"}
            </button>
          </div>

          <div></div>

          {clients &&
            sortedClients.map((client) => (
              <>
                {
                  <div key={client.clientid}>
                    <div className="col mt-5 mb-3">
                      <div className="d-flex m-1">
                        <div>
                          {client.eventimage !== null && (
                            <img
                              style={{ width: "150px", height: "100%" }}
                              className="img-fluid"
                              src={`${WEB.Backend}node/uploads/${client.eventimage}`}
                            />
                          )}
                        </div>
                        <div className="col">
                          <div
                            className="col bg-light text-dark p-2 rounded-2"
                            style={{ height: 100 }}
                          >
                            <div
                              className="m-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            ></div>
                            <div
                              className="m-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="col">
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>
                                  {client.headline}
                                </p>
                              </div>
                              <div className="col">
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>
                                  {client.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="col text-dark  rounded-2"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className="col">
                        

                            <button
                              className="col rounded-5 m-1 btn btn-warning"
                              onClick={() => handleUpdateClick(client)}
                            >
                              <FontAwesomeIcon icon={"edit"} /> 
                              Edit
                            </button>
                         
                            <button
                              className="col rounded-5 m-1 btn btn-light"
                              style={{ color: "red", fontSize: 18 }}
                              onClick={() => handleShowModal(client)}
                            >
                              <FontAwesomeIcon icon={"trash"} /> Delete
                            </button>
                            <Modal show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Profile</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                  <p>Do you want to delete this profile ?</p>
                                  <p>
                                    Name:
                                    {clientToDelete && clientToDelete.headline}{" "}
                                  </p>
                                  <p>
                                    headline:
                                    {clientToDelete &&
                                      clientToDelete.description}{" "}
                                  </p>
                                </div>
                                <div className="row p-3">
                                  <button
                                    className="btn btn-danger rounded-5 col"
                                    style={{ color: "white" }}
                                    onClick={() => handleDeleteClick(client)}
                                  >
                                    Yes
                                  </button>
                                </div>
                                <div className="row p-3">
                                  <button
                                    className="btn rounded-5 col"
                                    style={{
                                      backgroundColor: theme.COLORS.PRIMARY,
                                      color: "white",
                                    }}
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            ))}
        </div>{" "}
      </div>{" "}
    </>
  );
}
